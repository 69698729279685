@use'../config/variables/color.scss';
.button{
    padding: 12px;
    font-size:18px;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;

    &--primary{
        color: color.$textColorButton;
        background-color:color.$backgroundColorButton;
    }

    @media (max-width: 640px){
        display:  block;
        width: 100%;
    }
    
    @media (max-width: 1024px){
        display:  flex;
        align-items: center;
    }
}
