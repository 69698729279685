@use'config/variables/color.scss';

.event{
    padding: 80px 0;
    width: 100vw;
    height: auto;
    
    &--frontend{
        background-image: linear-gradient(color.$blueOneForLinearGradient,  color.$blueTwoForLinearGradient);
    }

    &--ui-ux{
        background-image: linear-gradient(color.$blueTwoForLinearGradient,  color.$pinkOneForLinearGradient);
    }

    &--data-science{
        background-image: linear-gradient(color.$pinkOneForLinearGradient,  color.$yellowOneForLinearGradient);
    }

    &--backend{
        background-image: linear-gradient(color.$yellowOneForLinearGradient,  color.$pinkOneForLinearGradient);
    }

    &--image-left{
        .container{
            flex-direction: row-reverse;
        }
    }
    .container{
        display: flex;
        gap: 40px;
    }

    &__details{
        color: white;

        &__title{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 16px;
        }

        &__description{
            line-height: 22px;
        }
    }

    &__image{
        max-width: 360px;
        max-height: 360px;
        
    }

    @media(max-width: 640px){
        .container{
            display: block;
        }

        &__image{
            width:100%;
        }

        &__details{
            margin-right:0;
            margin-bottom: 16px;

            &__title{
                text-align: center;
            }
        }
    }
}