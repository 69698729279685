@use'../config/variables/color.scss';
.infos-bar{
    padding: 16px 24px;
    background-color: color.$backgroundColorInfosBar;
    display: flex;
    justify-content: space-between;

    &__infos{
        display: flex;
        gap: 40px;
        align-content: center;

        &__item{
            font-size: 14px;
            color: color.$secundarytextColor;
            text-align: left;
            display: block;

            b{
                color: color.$secundarytextColor;
                font-size: 18px;
                display: block;
                text-align: left;
            }
        }
    }

    @media (max-width: 640px){
        display: block;
        &__infos{
            display: block;

            &__item{
                margin-bottom: 24px;
            }
        }
    }
    @media (max-width: 1024px){
        &__infos{
            gap: 16px;
        }
    }
}