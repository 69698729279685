
// linear gradient color 
$blueOneForLinearGradient:#1B6CA7;
$blueTwoForLinearGradient:#442BDB;
$pinkOneForLinearGradient:#89223B;
$yellowOneForLinearGradient:#9C791D;

//text color 
$textColor: white;
$secundarytextColor: #000;

//overlay color
$overlayColor:  rgba(0, 0, 0, 0.5);

//logo color 
$spanColor:#3d4cbe;

//Background-Color
$backgroundColorInfosBar: white;

//button color
$backgroundColorButton:#4f2ac3;
$textColorButton: white;