* {
  box-sizing: border-box;
  color: #fff;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  list-style-type: none;
}

.hero {
  background-image: url("fundo.45626ea2.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 64px;
  padding-bottom: 100px;
  position: relative;
}

.hero:before {
  width: 100%;
  height: 100%;
  content: "";
  background-color: #00000080;
  position: absolute;
  top: 0;
  left: 0;
}

.hero div {
  text-align: center;
  position: relative;
}

.hero__logo {
  font-family: Play, sans-serif;
  font-size: 60px;
}

.hero__logo span {
  color: #3d4cbe;
  font-size: 70px;
}

.hero__text {
  text-align: center;
  margin-top: 64px;
  margin-bottom: 64px;
  font-size: 40px;
  font-weight: 800;
  line-height: 56px;
}

@media (max-width: 640px) {
  .hero {
    padding: 40px 0;
  }

  .hero__logo {
    font-size: 40px;
  }

  .hero__logo span {
    font-size: 50px;
  }

  .hero__text {
    margin: 24px 0;
    font-size: 30px;
    line-height: 45px;
  }
}

.infos-bar {
  background-color: #fff;
  justify-content: space-between;
  padding: 16px 24px;
  display: flex;
}

.infos-bar__infos {
  align-content: center;
  gap: 40px;
  display: flex;
}

.infos-bar__infos__item {
  color: #000;
  text-align: left;
  font-size: 14px;
  display: block;
}

.infos-bar__infos__item b {
  color: #000;
  text-align: left;
  font-size: 18px;
  display: block;
}

@media (max-width: 640px) {
  .infos-bar, .infos-bar__infos {
    display: block;
  }

  .infos-bar__infos__item {
    margin-bottom: 24px;
  }
}

@media (max-width: 1024px) {
  .infos-bar__infos {
    gap: 16px;
  }
}

.button {
  text-align: center;
  white-space: nowrap;
  padding: 12px;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.button--primary {
  color: #fff;
  background-color: #4f2ac3;
}

@media (max-width: 640px) {
  .button {
    width: 100%;
    display: block;
  }
}

@media (max-width: 1024px) {
  .button {
    align-items: center;
    display: flex;
  }
}

.event {
  width: 100vw;
  height: auto;
  padding: 80px 0;
}

.event--frontend {
  background-image: linear-gradient(#1b6ca7, #442bdb);
}

.event--ui-ux {
  background-image: linear-gradient(#442bdb, #89223b);
}

.event--data-science {
  background-image: linear-gradient(#89223b, #9c791d);
}

.event--backend {
  background-image: linear-gradient(#9c791d, #89223b);
}

.event--image-left .container {
  flex-direction: row-reverse;
}

.event .container {
  gap: 40px;
  display: flex;
}

.event__details {
  color: #fff;
}

.event__details__title {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}

.event__details__description {
  line-height: 22px;
}

.event__image {
  max-width: 360px;
  max-height: 360px;
}

@media (max-width: 640px) {
  .event .container {
    display: block;
  }

  .event__image {
    width: 100%;
  }

  .event__details {
    margin-bottom: 16px;
    margin-right: 0;
  }

  .event__details__title {
    text-align: center;
  }
}

.container {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
}

@media (max-width: 640px) {
  .container {
    max-width: 80%;
  }
}

@media (max-width: 1024px) {
  .container {
    max-width: 90%;
  }
}

/*# sourceMappingURL=index.291dd937.css.map */
